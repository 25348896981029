import React, { useEffect, useRef, useState, useContext } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Image from '@editorjs/image';
import Code from '@editorjs/code';
import Table from '@editorjs/table';
import Embed from '@editorjs/embed';
import LinkTool from '@editorjs/link';
import InlineCode from '@editorjs/inline-code';
import Marker from '@editorjs/marker';
import Warning from '@editorjs/warning';
import Raw from '@editorjs/raw';
import Quote from '@editorjs/quote';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import AutoComplete from '@editorjs/link-autocomplete';
import Underline from '@editorjs/underline';
import { Button, Input } from 'antd';
import AttachesTool from '@editorjs/attaches';
import { Axis } from 'echarts';
import axios from 'axios';

import ColorPlugin from 'editorjs-text-color-plugin';

import { requestHeaders } from '../utils/Utils';
import { docURL } from '../helper/APIConfig';
import styled from '@emotion/styled';
import { Context } from '../store/Store';
import { Edit } from 'react-feather';



const StyledContainer = styled.div`
    .ce-block__content, .ce-toolbar__content {
        max-width: 85% !important;
    }
`

const MenuItemContent = ({ menuItem, contentList = [], loadingFn = false, setAlertDetails, parentList }) => {
    const [deviceState, dispatch] = useContext(Context);
    const [contents, setContents] = useState([]);
    const [nextId, setNextId] = useState(1);
    const [selectedPageList, setSelectedPageList] = useState({});
    const [parentPageCode, setParentPageCode] = useState('');
    const [currentParentDetails, setCurrentParentDetails] = useState({});
    const [isModified, setIsModified] = useState(true);
    const [isEditPage, setIsEditPage] = useState(false);
    const [isRefreshdata, setIsRefreshData] = useState(false);


    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(menuItem.title);

    const editorRef = useRef(null);



    useEffect(() => {
        dispatch({ type: 'DOCUMENTATION', payload: { isPageEdit: isEditPage, onSave: saveContent, onDestroy: destroyContent } });
    }, [isEditPage]);

    useEffect(() => {
        console.log('cuv09', menuItem, title, contentList);
        setTitle(menuItem.title);
    }, [menuItem])


    // useEffect(() => {
    //    console.log('cuv97686', editorRef);

    //    editorRef.current?.destroy?.();
    //    editorRef.current?.destroy?.();

    //             if(menuItem?.title){
    //                 setTitle(menuItem.title);

    //                 editorRef.current = new EditorJS({
    //                     holder: `editorjs-${menuItem.title}`,
    //                     tools: {
    //                         header: Header,
    //                         list: List,
    //                         image: {
    //                             class: Image,
    //                             config: {
    //                                 uploader: {
    //                                     uploadByFile: async (file) => {
    //                                         try {
    //                                             // Create FormData instance
    //                                             const formData = new FormData();
    //                                             formData.append('files', file);

    //                                             // Make request to your backend endpoint
    //                                             const response = await axios.post(
    //                                                 `${docURL}/document/upload`,
    //                                                 formData,
    //                                                 {
    //                                                     ...requestHeaders(),
    //                                                     headers: {
    //                                                         ...requestHeaders().headers,
    //                                                         'Content-Type': 'multipart/form-data',
    //                                                     },
    //                                                 }
    //                                             );

    //                                             const { details } = response.data ?? {};
    //                                             // Return object with success and file URL
    //                                             return {
    //                                                 success: 1,
    //                                                 file: {
    //                                                     url: details.uploads[0].fileurl,
    //                                                     // You can also include additional properties:
    //                                                     name: details.uploads[0].filename,
    //                                                     size: response.data.size
    //                                                 }
    //                                             };
    //                                         } catch (error) {
    //                                             console.error('Image upload failed:', error);
    //                                             setAlertDetails({
    //                                                 showAlert: true,
    //                                                 alertType: 'Error',
    //                                                 alertMsg: 'Image upload failed'
    //                                             });
    //                                             return {
    //                                                 success: 0,
    //                                                 error: 'Image upload failed'
    //                                             };
    //                                         }
    //                                     },
    //                                     uploadByUrl: async (url) => {
    //                                         try {
    //                                             // Make request to your backend endpoint
    //                                             const response = await axios.post(
    //                                                 `${docURL}/document/upload_image_url`,
    //                                                 { url },
    //                                                 { ...requestHeaders() }
    //                                             );

    //                                             return {
    //                                                 success: 1,
    //                                                 file: {
    //                                                     url: response.data.url
    //                                                 }
    //                                             };
    //                                         } catch (error) {
    //                                             console.error('Image URL upload failed:', error);
    //                                             return {
    //                                                 success: 0,
    //                                                 error: 'Image URL upload failed'
    //                                             };
    //                                         }
    //                                     }
    //                                 }
    //                             }   
    //                         }, 
    //                         code: Code,
    //                         table: Table,
    //                         embed: Embed,
    //                         linkTool: LinkTool,
    //                         inlineCode: InlineCode,
    //                         marker: Marker,
    //                         warning: {
    //                             class: Warning,
    //                             inlineToolbar: true,
    //                             shortcut: 'CMD+SHIFT+W',
    //                             config: {
    //                               titlePlaceholder: 'Title',
    //                               messagePlaceholder: 'Message',
    //                             }
    //                         },
    //                         raw: Raw,
    //                         quote: {
    //                             class: Quote,
    //                             inlineToolbar: true,
    //                             shortcut: 'CMD+SHIFT+O',
    //                             config: {
    //                               quotePlaceholder: 'Enter a quote',
    //                               captionPlaceholder: 'Quote\'s author',
    //                             },
    //                         },
    //                         Color: {
    //                             class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    //                             config: {
    //                                colorCollections: ['#EC7878','#9C27B0','#673AB7','#3F51B5','#0070FF','#03A9F4','#00BCD4','#4CAF50','#8BC34A','#CDDC39', '#FFF'],
    //                                defaultColor: '#FF1300',
    //                                type: 'text', 
    //                                customPicker: true // add a button to allow selecting any colour  
    //                             }     
    //                           },
    //                         checkList: CheckList,
    //                         delimiter: Delimiter,
    //                         autoComplete: AutoComplete,
    //                         underline: Underline,
    //                         attaches: {
    //                             class: AttachesTool,
    //                             config: {
    //                               uploader: {
    //                                 async uploadByFile(file) {
    //                                   try {
    //                                     const formData = new FormData();
    //                                     formData.append('files', file);

    //                                     const response = await axios.post(
    //                                       `${docURL}/document/upload`,
    //                                       formData,
    //                                       {
    //                                         ...requestHeaders(),
    //                                         headers: {
    //                                           ...requestHeaders().headers,
    //                                           'Content-Type': 'multipart/form-data',
    //                                         },
    //                                       }
    //                                     );

    //                                     const { details } = response.data ?? {};
    //                                     const fileUrl = details.uploads[0].fileurl;
    //                                     const fileName = details.uploads[0].filename;
    //                                     const fileType = file.type;

    //                                     // Force download for text files by creating a Blob
    //                                     if (fileType === 'text/plain') {
    //                                       const blob = new Blob([file], { type: fileType });
    //                                       const downloadUrl = URL.createObjectURL(blob);
    //                                       const link = document.createElement('a');
    //                                       link.href = downloadUrl;
    //                                       link.download = fileName;
    //                                       document.body.appendChild(link);
    //                                       link.click();
    //                                       document.body.removeChild(link);
    //                                       URL.revokeObjectURL(downloadUrl); // Clean up the URL object
    //                                     }

    //                                     return {
    //                                       success: 1,
    //                                       file: {
    //                                         url: fileUrl,
    //                                         name: fileName,
    //                                         size: file.size,
    //                                       },
    //                                     };
    //                                   } catch (error) {
    //                                     console.error('File upload failed:', error);
    //                                     return {
    //                                       success: 0,
    //                                       error: 'File upload failed',
    //                                     };
    //                                   }
    //                                 },
    //                               },
    //                             },
    //                           }


    //                     },
    //                     data: getContentByMenuItemTitle(menuItem),
    //                     onChange: () => {
    //                         setIsModified(false);
    //                         setIsEditPage(true);
    //                     }
    //                 });
    //             }


    //         // ... rest of your existing code remains the same

    //         return () => {
    //             // Destroy the editor instance on unmount to prevent duplication
    //             editorRef.current?.destroy?.();
    //         };

    // }, [isRefreshdata, contentList, menuItem]);


    // import { useRef, useEffect } from 'react';

    // // First, declare your ref outside the effect
    // const editorRef = useRef(null);

    // Then in your component:
    useEffect(() => {
        let editor = null;

        const initEditor = async () => {
            // Safety check to prevent multiple instances
            if (editor || !menuItem?.title) {
                return;
            }

            try {
                // Clean up existing instance if it exists
                if (editorRef.current && typeof editorRef.current.destroy === 'function') {
                    await editorRef.current.destroy();
                    editorRef.current = null;
                }

                // Initialize new EditorJS instance
                editor = new EditorJS({
                    holder: `editorjs-${menuItem.title}`,
                    tools: {
                        header: Header,
                        list: List,
                        image: {
                            class: Image,
                            config: {
                                uploader: {
                                    uploadByFile: async (file) => {
                                        try {
                                            // Create FormData instance
                                            const formData = new FormData();
                                            formData.append('files', file);

                                            // Make request to your backend endpoint
                                            const response = await axios.post(
                                                `${docURL}/document/upload`,
                                                formData,
                                                {
                                                    ...requestHeaders(),
                                                    headers: {
                                                        ...requestHeaders().headers,
                                                        'Content-Type': 'multipart/form-data',
                                                    },
                                                }
                                            );

                                            const { details } = response.data ?? {};
                                            // Return object with success and file URL
                                            return {
                                                success: 1,
                                                file: {
                                                    url: details.uploads[0].fileurl,
                                                    // You can also include additional properties:
                                                    name: details.uploads[0].filename,
                                                    size: response.data.size
                                                }
                                            };
                                        } catch (error) {
                                            console.error('Image upload failed:', error);
                                            setAlertDetails({
                                                showAlert: true,
                                                alertType: 'Error',
                                                alertMsg: 'Image upload failed'
                                            });
                                            return {
                                                success: 0,
                                                error: 'Image upload failed'
                                            };
                                        }
                                    },
                                    uploadByUrl: async (url) => {
                                        try {
                                            // Make request to your backend endpoint
                                            const response = await axios.post(
                                                `${docURL}/document/upload_image_url`,
                                                { url },
                                                { ...requestHeaders() }
                                            );

                                            return {
                                                success: 1,
                                                file: {
                                                    url: response.data.url
                                                }
                                            };
                                        } catch (error) {
                                            console.error('Image URL upload failed:', error);
                                            return {
                                                success: 0,
                                                error: 'Image URL upload failed'
                                            };
                                        }
                                    }
                                }
                            }
                        },
                        code: Code,
                        table: Table,
                        embed: Embed,
                        linkTool: LinkTool,
                        inlineCode: InlineCode,
                        marker: Marker,
                        warning: {
                            class: Warning,
                            inlineToolbar: true,
                            shortcut: 'CMD+SHIFT+W',
                            config: {
                                titlePlaceholder: 'Title',
                                messagePlaceholder: 'Message',
                            }
                        },
                        raw: Raw,
                        quote: {
                            class: Quote,
                            inlineToolbar: true,
                            shortcut: 'CMD+SHIFT+O',
                            config: {
                                quotePlaceholder: 'Enter a quote',
                                captionPlaceholder: 'Quote\'s author',
                            },
                        },
                        Color: {
                            class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
                            config: {
                                colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
                                defaultColor: '#FF1300',
                                type: 'text',
                                customPicker: true // add a button to allow selecting any colour  
                            }
                        },
                        checkList: CheckList,
                        delimiter: Delimiter,
                        autoComplete: AutoComplete,
                        underline: Underline,
                        attaches: {
                            class: AttachesTool,
                            config: {
                                uploader: {
                                    async uploadByFile(file) {
                                        try {
                                            const formData = new FormData();
                                            formData.append('files', file);

                                            const response = await axios.post(
                                                `${docURL}/document/upload`,
                                                formData,
                                                {
                                                    ...requestHeaders(),
                                                    headers: {
                                                        ...requestHeaders().headers,
                                                        'Content-Type': 'multipart/form-data',
                                                    },
                                                }
                                            );

                                            const { details } = response.data ?? {};
                                            const fileUrl = details.uploads[0].fileurl;
                                            const fileName = details.uploads[0].filename;
                                            const fileType = file.type;

                                            // Force download for text files by creating a Blob
                                            if (fileType === 'text/plain') {
                                                const blob = new Blob([file], { type: fileType });
                                                const downloadUrl = URL.createObjectURL(blob);
                                                const link = document.createElement('a');
                                                link.href = downloadUrl;
                                                link.download = fileName;
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                                URL.revokeObjectURL(downloadUrl); // Clean up the URL object
                                            }

                                            return {
                                                success: 1,
                                                file: {
                                                    url: fileUrl,
                                                    name: fileName,
                                                    size: file.size,
                                                },
                                            };
                                        } catch (error) {
                                            console.error('File upload failed:', error);
                                            return {
                                                success: 0,
                                                error: 'File upload failed',
                                            };
                                        }
                                    },
                                },
                            },
                        }


                    },
                    data: getContentByMenuItemTitle(menuItem),
                    onChange: () => {
                        setIsModified(false);
                        setIsEditPage(true);
                    },
                    onReady: () => {
                        // Store the editor instance once it's ready
                        editorRef.current = editor;
                    },
                });
            } catch (error) {
                console.error('Error initializing editor:', error);
            }
        };

        // Initialize the editor
        initEditor();

        // Cleanup function
        return () => {
            const cleanup = async () => {
                try {
                    if (editorRef.current && typeof editorRef.current.destroy === 'function') {
                        await editorRef.current.destroy();
                        editorRef.current = null;
                    }
                } catch (error) {
                    console.error('Error destroying editor:', error);
                }
            };

            cleanup();
        };
    }, [menuItem?.title, isRefreshdata, contentList]); // Keep only necessary dependencies


    // const getParentPage = (path) => {
    //     const parts = path.split('/');
    //     return parts.length > 1 ? parts[parts.length - 2] : null;
    // };

    function getParentPageCode(pageCode, data) {
        // Remove the last part after the dot to get the parent code
        const parentCode = pageCode.includes('.') ? pageCode.substring(0, pageCode.lastIndexOf('.')) : null;
        // Check if the extracted parent code exists in the data
        const parentPage = data.find(item => item.pageCode === parentCode);

        setParentPageCode(parentCode)

        // Return the parent code if found, otherwise return null
        return parentPage ? parentPage.pageCode : null;
    }


    // Function to retrieve content by menuItem title
    function getContentByMenuItemTitle(cardTitle) {
        setParentPageCode(parentList.pageCode);

        if (contentList.length > 0) {
            const dataList = contentList.find((list) => {
                return menuItem.title === list.title
            });

            // const getParentPage = contentList.find((list) => menuItem.title.startsWith(list.pageCode));

            setSelectedPageList(dataList);


            console.log('cuv7555', dataList);
            const res = {
                blocks: dataList?.pageContent?.length > 0 ? dataList.pageContent : []
            };


            return res;

        }

        return {
            blocks: [
                // {
                //     type: 'paragraph', 
                //     data: { text: 'This is a pargarph'}
                // },
                // {
                //     type: 'header', 
                //     data: { text: 'This is a heading' },
                // },
                // {
                //     type: 'image', 
                //     data: { text: 'This is a file' },
                // },
            ]
        }
    }

    const updateDocuments = (documentData) => {
        loadingFn(true);
        // const parentPagecode = selectedPageList?.path ? getParentPage(selectedPageList.path): "";

        const { name, outputData: { blocks } } = documentData[0] ?? {};

        let contentList = [];
        if (blocks?.length > 0) {
            contentList = blocks.map((list) => {
                return {
                    type: list.type,
                    data: list.data
                }
            })
        }

        const payload = {
            "title": title ? title : name,
            "content": contentList,
            "user_role": "owner",
            "doc_type": "zoom"
        };

        const url = `${docURL}/document/update_document/${menuItem.pageCode}`;

        axios.put(url, payload, { ...requestHeaders() })
            .then(response => {
                // setAlertDetails({ sh})
                // setTitle(name);
                const { message, status } = response.data ?? {};
                setAlertDetails({ showAlert: true, alertType: status, alertMsg: message })
            })
            .catch(error => {
                console.error(`Error fetching :`, error);
                const message = error.response?.data?.message ? error.response.data.message : error.message;
                setAlertDetails({ showAlert: true, alertType: 'Error', alertMsg: message })

            }).finally(() => {
                loadingFn(false);
            })
    };

    const saveDocuments = (documentData) => {
        loadingFn(true);
        // const parentPagecode = selectedPageList?.path ? getParentPage(selectedPageList.path): "";

        const { name, outputData: { blocks } } = documentData[0] ?? {};

        let contentList = [];
        if (blocks?.length > 0) {
            contentList = blocks.map((list) => {
                return {
                    type: list.type,
                    data: list.data
                }
            })
        }

        const payload = {
            "title": name,
            "content": contentList,
            "user_role": "owner",
            "doc_type": "zoom"
        };

        const url = parentPageCode ? `${docURL}/document/sub/?parent_page_code=${parentPageCode}` : `${docURL}/document/main`;

        axios.post(url, payload, { ...requestHeaders() })
            .then(response => {
                setIsEditPage(false);
                // setAlertDetails({ sh})
                const { message, status } = response.data ?? {};
                setAlertDetails({ showAlert: true, alertType: status, alertMsg: message })
            })
            .catch(error => {
                console.error(`Error fetching :`, error);
                const message = error.response?.data?.message ? error.response.data.message : error.message;
                setAlertDetails({ showAlert: true, alertType: 'Error', alertMsg: message })

            }).finally(() => {
                loadingFn(false);
            })
    };

    const destroyContent = () => {
        setIsRefreshData((prevState) => !prevState);
        setIsEditPage(false);
    }

    function saveContent() {
        if (!editorRef.current) return;

        console.log('cuv756', editorRef.current, contents);

        editorRef.current
            .save()
            .then((outputData) => {
                console.log(menuItem);
                console.log('outputData: ', outputData, menuItem);

                // Determine parent ID if exists
                let parentId = 0;
                contents.some(item => {
                    if (item.menuItem.children?.some(child => child.title === menuItem.title)) {
                        parentId = item.id;
                        return true; // exit early
                    }
                    return false;
                });

                // Check if the menuItem.title already exists in contents
                const existingIndex = contents.findIndex(
                    (content) => content.name === menuItem.title
                );

                if (existingIndex !== -1) {
                    // Update existing content
                    const updatedContents = contents.map((content, index) =>
                        index === existingIndex
                            ? { ...content, outputData }
                            : content
                    );
                    setContents(updatedContents);
                    selectedPageList?.pageCode ? updateDocuments(updatedContents) : saveDocuments(updatedContents);
                } else {
                    // Create new content object if it doesn’t exist
                    const newContent = {
                        id: nextId,
                        name: menuItem.title,
                        menuItem,
                        outputData,
                        parentId
                    };

                    setContents(prevContents => {
                        const newContents = [...prevContents, newContent];
                        selectedPageList?.pageCode ? updateDocuments(newContents) : saveDocuments(newContents);
                        return newContents;
                    });

                    // Increment nextId
                    setNextId(prevId => prevId + 1);
                }

                console.log("Final data ", contents);
            })
            .catch((error) => {
                console.error('Error saving content:', error);
            });
    }

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        // onSave(title); // Call the onSave function to save the title
    };



    return (
        <StyledContainer>
            {/* {JSON.strsingify(isEditPage)} */}
            <div className="nest-btn">
                {isEditing ? (
                    <Input
                        style={{ width: "80%" }}
                        value={title}
                        onChange={(e) => {
                            console.log('cuv76', menuItem,);
                            setTitle(e.target.value);
                            setIsModified(false);
                        }
                        }
                        onPressEnter={handleSaveClick} // Save on pressing Enter
                        onBlur={handleSaveClick} // Save on losing focus
                    />
                ) : (
                    <div className='d-flex align-items-center column-gap-20' style={{ marginBottom: '0.5em' }}>
                        <h2 style={{ margin: '0px', color: 'var(--secondary-color)' }}>{title}</h2>
                        <Edit onClick={handleEditClick} color='var(--secondary-color)' style={{ cursor: 'pointer' }} />
                    </div>
                )}
                {/* {isEditing && (
                <Button type="primary" onClick={handleSaveClick}>
                    Save
                </Button>
            )}                */}

                <Button
                    className="add-sub-button"
                    type="primary"
                    disabled={isModified}
                    size="small"
                    onClick={saveContent}
                >
                    Save
                </Button>
            </div>
            {/* {JSON.stringify(selectedPageList)}  */}
            <div id={`editorjs-${menuItem.title}`}></div>
        </StyledContainer>
    );
};

export default MenuItemContent;
