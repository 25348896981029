import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert'; 

const ModalAlert = ({ 
    alertMsg, 
    alertType = 'Success', 
    showAlert = false,
    alertFn = () => {},
    onConfirmHandler = () => {},
}) => {


    const [visibleAlert, setVisibleAlert] = useState(showAlert);

    return (
        <SweetAlert
            show={visibleAlert}
            title={alertType}
            type={alertType.toLowerCase()} 
            onConfirm={() => {
                setVisibleAlert(false);
                alertFn(false);
                onConfirmHandler(); 
            }}
        >
            <p className="sweet-alert-text">{alertMsg}</p>
        </SweetAlert>
    );
};

export default ModalAlert;
