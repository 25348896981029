import styled from '@emotion/styled';
import { Layout, Menu, Tooltip } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import * as Icon from "react-feather";
import { FaFileAlt } from "react-icons/fa";
import { Redirect, useLocation } from "react-router-dom";
import { ReactComponent as CPESvg } from '../assets/images/swan-cpe.svg';
import { MdManageAccounts } from "react-icons/md";
import DashboardHeader from '../components/DashboardHeader';
import HelpLayout from './HelpLayout';
import {sidebarList, NewUserLists, sdwanMenu, singleVirtualboxMenu, zoomMenu} from "./sidebar-list";
import { Context } from '../store/Store';
import { getSubscriptionDetails, requestHeaders } from '../utils/Utils';
import { ErrorBoundary } from "react-error-boundary";
import ErrorboundaryMessage from '../components/ErrorboundaryMessage.js';
import { SUBSCRIPTION } from '../helper/APIConfig.js';
import axios from 'axios';





const StyledAdminLayout = styled.div`
    .ant-menu-submenu-arrow {
        transform: rotate(-90deg);
        visibility: ${props => props.collapsed ? "hidden" : "visible"};
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        transform: rotate(-180deg) !important;
    }
`;

var moment = require('moment');
var momentz = require("moment-timezone");

const { SubMenu } = Menu;

export default function AdminLayout(props) {
    const [loading, setLoading] = useState(false);
    // const [enableSdwan, setEnableSdwan] = useState(false);
    const [deviceState, dispatch] = useContext(Context);
    const location = useLocation();
    const pathMatchRoute = (route) => route === location.pathname;
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [menuLists, setMenuLists] = useState([]);
    const idleTimeout = 30 * 60 * 1000; // 30 minutes in milliseconds

    var tz = momentz.tz.guess();
    var time = new Date();
    var timeZoneOffset = time.getTimezoneOffset();
    let timeZone = moment.tz.zone(tz).abbr(timeZoneOffset);

    let isLogin = false;
    let userName = "";
    const isLoggedIn = localStorage.getItem("currentUser");
    if (isLoggedIn) {
        var user = JSON.parse(isLoggedIn);
        if (user) {
            userName = user.name;
            isLogin = true;
        }
    }
    let userDetails = localStorage.getItem('userDetails');
    let accountDetails = [];

    if (userDetails) {
        accountDetails = JSON.parse(userDetails);
    }
    let datetime = moment(new Date()).format("lll");

    const { Header, Sider, Content } = Layout;
    const [collapsed, setCollapsed] = useState(false);
    const [currentDateTime, setcurrentDateTime] = useState(datetime + " " + timeZone);

    
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [])
    
    useEffect(() => {
        if(localStorage.getItem("currentUser")) setAuthenticated(true)
    }, []);

    
    // useEffect(() => {
    //     const storedColors = JSON.parse(sessionStorage.getItem('themeColors'));
    //     if (storedColors) {
    //     //   setColors(storedColors);
    //     //   setPendingColors(storedColors);
    //       Object.keys(storedColors).forEach(colorKey => {
    //         document.documentElement.style.setProperty(`--${colorKey}`, storedColors[colorKey]);
    //       });
    //     }
    //   }, []);

    useEffect(() => {
        let timer;
        const handleIdle = () => {
          window.location.href = '/login'; // Redirect to login page
          localStorage.clear();
        };
    
        const handleActivity = () => {
          clearTimeout(timer);
          timer = setTimeout(handleIdle, idleTimeout);
        };
    
        // Add event listeners for user activity
        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('keydown', handleActivity);
        document.addEventListener('touchstart', handleActivity);
    
        // Start the timer initially
        timer = setTimeout(handleIdle, idleTimeout);
    
        return () => {
          // Remove event listeners on component unmount
          document.removeEventListener('mousemove', handleActivity);
          document.removeEventListener('keydown', handleActivity);
          document.removeEventListener('touchstart', handleActivity);
          clearTimeout(timer);
        };
      }, []);

    useEffect(() => {
        const getSubscriptionDetails = () => {
            setLoading(true);
            axios.get(`${SUBSCRIPTION}/${accountDetails.org}`, {...requestHeaders()})
                .then((res) => {
                    const subscription_list = res.data;
                    localStorage.setItem('subscriptionDetails', JSON.stringify(subscription_list)); 
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching subscription details:', error);
                    setLoading(false);
                });
        }

        getSubscriptionDetails();
    }, []);

    const zoomAdminMenu = [{
        id: "zoom_admin",
        title: "Zoom",
        type: "collapse",
        order: 3,
        icon: <MdManageAccounts size={20} />,
        children: [
            {
                id: "admin-dashboard",
                title: "Dashboard",
                type: "item",
                icon: <Icon.User size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/admin-dashboard"
            },
            {
                id: "2",
                title: "Accounts",
                type: "item",
                icon: <Icon.User size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/zoom-accounts"
            },
            {
                id: "audit",
                title: "Audit",
                type: "item",
                icon: <Icon.User size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/zoom-logs"
            }
        ]
    }
];

    useEffect(async () => { 
        const permissionTypes = localStorage.getItem('permissionTypes');

        let getPermissionData = {}; 

        if(getPermissionData) getPermissionData = JSON.parse(permissionTypes);

        const subscriptionMenus = await getSubscriptionDetails();
        
        let sideBarlists = [];
        if(getPermissionData?.to === 'account') sideBarlists.push(...sidebarList);
        else if(getPermissionData?.to === 'organization') sideBarlists.push(...NewUserLists);
        else if(getPermissionData?.to === 'group') sideBarlists.push(...NewUserLists);
        else sideBarlists.push(...NewUserLists); 

        
        if(subscriptionMenus.includes('svb')) sideBarlists= [...sideBarlists, ...singleVirtualboxMenu];
        if(subscriptionMenus.includes('sdwan')) sideBarlists= [...sideBarlists, ...sdwanMenu];  
        if(subscriptionMenus.includes('zoom')) {
           const addAdminZoomMenu = sideBarlists.map((list) => {
                if(list.id === 'administration'){
                    list.children = [...zoomAdminMenu, ...list.children];
                    return list
                }
                return list
           })
            sideBarlists= [...addAdminZoomMenu, ...zoomMenu]; 
        }
           
        
        if(sideBarlists.length > 0){
          setMenuLists(sideBarlists.sort((a, b) => a.order - b.order));
        }
    }, []) 
   
    const handleClick = e => {
        props.history.push(e.key);
    };
    const toggle = () => {
        setCollapsed(
            prevState => !prevState
        );
    };

    function logOut() {
        localStorage.removeItem('isUserLoggedIn');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userIpAddress');
        // sessionStorage.removeItem('sdwan')
        props.history.push("/login");
    }
    const menu = (
        <Menu>
            <Menu.Item>
                <a href="#" onClick={() => props.history.push("/profile")}>
                    <span><Icon.User size={14} className="mr-50" /></span>
                    <span> Edit Profile</span>
                </a>
            </Menu.Item>
            <Menu.Item>
                <a href="#" onClick={() => logOut()}>
                    <span> <Icon.Power size={14} className="mr-50" /></span>
                    <span>  Logout</span>
                </a>
            </Menu.Item>
        </Menu>
    );

    const activeStyle = {
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "rgb(105, 105, 105, .3)"
    }

   


    const dashboardHeaderProps = {
        collapsed: collapsed,
        toggle: toggle,
        history: props.history,
        currentDateTime: currentDateTime,
        userName: userName,
        accountDetails: accountDetails,
    }

    const determineLayout = (route) => {
        const { pathname } = route;
        if (pathname === '/help-menu') {
            return HelpLayout; // Use a special layout for this menu item
        }
        // Use the default layout for all other menu items
        return Layout;
    };

    const LayoutComponent = determineLayout(location);
    return isLogin ? (
        (<StyledAdminLayout collapsed={collapsed}>
            <LayoutComponent>
                <Sider trigger={null} collapsible collapsed={collapsed}
                    style={{ height: '100vh', maxHeight: "100vh", overflow: "auto" }}>
                    <div className="logo" style={{ textAlign: 'center' }}>
                        {/* {!collapsed && <img src={require("../assets/images/youcloud-logo.png").default} alt="youcloud-logo" width="150px" />} */}
                        {!collapsed && <CPESvg style={{ height: '70px', width: '70px', marginTop: '-0.4rem' }} />}
                    </div>
                    <Menu style={{ padding: "10px" }} theme="dark" mode="inline" defaultSelectedKeys={[props.location.pathname]} onClick={handleClick}>
                        {menuLists.map(item => {
                            return (
                                <>
                                    {item.type === "item" && item.redirectExternal && (
                                        <a href={item.navLink} target="_blank" className="ant-menu-item external-link" rel="noopener noreferrer">
                                            <FaFileAlt className="ant-menu-item-icon" size={20} />
                                            <span className="ant-menu-title-content">{item.title}</span>
                                        </a>
                                    )}
                                    {item.type === "item" && !item.redirectExternal && (
                                        <Menu.Item key={item.navLink} icon={item.icon}>
                                            {item.title}
                                        </Menu.Item>      
                                    )}
                                    {(item.type === "double-collapse") && (
                                        <>

                                            <SubMenu key={item.id} icon={item.icon} title={item.title}>
                                                {
                                                    item.children?.map(subItem => (
                                                        <>
                                                         {
                                                            subItem.id === 'documents' && subItem.redirectExternal ? 
                                                                <a href={subItem.navLink} target="_blank" style={{marginLeft: '23px'}} className="ant-menu-item external-link" rel="noopener noreferrer">
                                                                        {subItem.icon}
                                                                    <span className="ant-menu-title-content" >{subItem.title}</span>
                                                                </a> : 
                                                                <>
                                                                {subItem.type === "item" && !subItem.redirectExternal ? (
                                                                <Menu.Item key={subItem.navLink} /* icon={subItem.icon} */>
                                                                    {subItem.title}
                                                                </Menu.Item>
                                                            ) : (
                                                                <SubMenu key={subItem.id} /* icon={subItem.icon} */ title={subItem.title}>
                                                                    {
                                                                        subItem?.children?.map(current => (
                                                                            <>
                                                                                {
                                                                                    current.tooltipTitle ? (
                                                                                        <Menu.Item key={current.navLink} /* icon={current.icon} */><Tooltip title={current.tooltipTitle}><span>{current.title}</span></Tooltip></Menu.Item>
                                                                                    ) : (
                                                                                        <Menu.Item key={current.navLink} /* icon={current.icon} */>{current.title}</Menu.Item>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        ))
                                                                    }
                                                                </SubMenu>
                                                            )}
                                                                </>
                                                         }
                                                            

                                                        </>

                                                    ))
                                                }
                                            </SubMenu>
                                        </>
                                    ) 
                                }
                                    {item.type === "collapse" && (
                                        <SubMenu key={item.id} icon={item.icon} title={item.title}>
                                            {item.children.map(c => {
                                                return (
                                                    c.title === 'Documentation'?
                                                        <a href={c.navLink} target="_blank" className="ant-menu-item external-link" style={{ paddingLeft: "48px" }} rel="noopener noreferrer">
                                                            <FaFileAlt className="ant-menu-item-icon" size={20} />
                                                            <span className="ant-menu-title-content" >97{c.title}</span>
                                                        </a> :
                                                        (c.title === 'Profile' &&
                                                            accountDetails.perms.accounts === 0 ?
                                                            <></> 
                                                            : c.id === 'documents' && c.redirectExternal ? 
                                                            <a href={c.navLink} target="_blank" style={{marginLeft: '23px'}} className="ant-menu-item external-link" rel="noopener noreferrer">
                                                                 {c.icon}
                                                                <span className="ant-menu-title-content" >{c.title}</span>
                                                          </a>   : c.showSubIcon ?  
                                                             <Menu.Item key={c.navLink} icon={c.icon}>{c.title}</Menu.Item> : <Menu.Item key={c.navLink}>{c.title}</Menu.Item>) 
                                                )
                                            })}
                                        </SubMenu>
                                    )}
                                </>
                            )
                        })}
                        {/* {sidebarList.map(item => {
                            return (
                                <>
                                    {
                                        item.type === "item" ? item.redirectExternal ?
                                            <a href={item.navLink} target="_blank" className="ant-menu-item external-link" rel="noopener noreferrer">
                                                <FaFileAlt className="ant-menu-item-icon" size={20} />
                                                <span className="ant-menu-title-content">{item.title}</span>
                                            </a>
                                            : <Menu.Item key={item.navLink} icon={item.icon}>
                                                {item.title}
                                            </Menu.Item> :
                                            <SubMenu key={item.id} icon={item.icon} title={item.title}>
                                                {item.children.map(c => {
                                                    return (
                                                        c.title === 'Documentation' ?
                                                            <a href={c.navLink} target="_blank" className="ant-menu-item external-link" style={{ paddingLeft: "48px" }} rel="noopener noreferrer">
                                                                <FaFileAlt className="ant-menu-item-icon" size={20} />
                                                                <span className="ant-menu-title-content" >{c.title}</span>
                                                            </a> :
                                                            ( c.title === 'Profile'  && 
                                                            accountDetails.perms.accounts === 0 ? 
                                                            <></>
                                                            :  <Menu.Item key={c.navLink} icon={c.icon}>{c.title}</Menu.Item> )
                                                    )
                                                })}
                                            </SubMenu>
                                    }
                                </>
                            )
                        })} */}

                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    {/*<Header className="site-layout-background" style={{ padding: 0, display: "flex", alignItems: "center", gap: "2.5rem" }}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: toggle,
                    })}
                    <StyledDashboardMenuHandler>
                        <StyledDashboardMenuItem  style={pathMatchRoute("/dashboard") ? activeStyle : {}}>Sdewan</StyledDashboardMenuItem>
                        <StyledDashboardMenuItem style={pathMatchRoute("/firewall-dashboard") ? activeStyle : {}}><Link to="/firewall-dashboard">Firewall</Link></StyledDashboardMenuItem>
                        <StyledDashboardMenuItem style={pathMatchRoute("/cpe-dashboard") ? activeStyle : {}}><Link to="/cpe-dashboard">Cpe</Link></StyledDashboardMenuItem>
                    </StyledDashboardMenuHandler>
                    <span className="current-date"><b>Local:  <span style={{ color: "#24abdb" }}>{currentDateTime}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="#" onClick={() => props.history.push("/ticket")}>
                                <span><FaRegHandPaper size={16} style={{color:"black"}} /></span>&nbsp;&nbsp;&nbsp;
                            </a>
                            <a href="#" onClick={() => props.history.push("/quick-help")}>
                                <span><Icon.HelpCircle  size={16} style={{color:"black"}} /></span>&nbsp;&nbsp;&nbsp;&nbsp;
                            </a>
                            <a href="https://docs.youcloudwan.com/user-guides/" onClick={() => props.history.push()}>
                                <span><FaFileAlt size={16} style={{color:"black"}} /></span>
                            </a>
                    </span> </b></span>
                    <Dropdown overlay={menu} placement="bottomCenter" arrow={true}>
                        <span><span style={{ fontWeight: 600, fontSize: 13, color: "#626262" }}>{userName}</span> <br />
                            <span style={{ fontSize: 12 }}>{accountDetails.orgName}</span>
                        </span>
                    </Dropdown>
                </Header> */}
                    <DashboardHeader {...dashboardHeaderProps} />
                    <Content
                        className={props.location.pathname !== "/" ? "site-layout-background" : ""}
                        style={{
                            margin: '16px',
                            minHeight: 280,
                            maxHeight: "calc(100vh - 100px)",
                            overflow: "auto"
                        }}
                    >
                        {/* { loading ? (<StyledLoadingWrapper><DashboardLoading /></StyledLoadingWrapper>) : <>{props.children}</>} */}
                        <ErrorBoundary fallback={<ErrorboundaryMessage />}  onReset={() => (location.href = '/home')} >
                            {props.children}
                        </ErrorBoundary>
                    </Content>
                </Layout>
            </LayoutComponent>
        </StyledAdminLayout>
        )
    ) :
        (
            <Redirect replace to="/" />
        )
}