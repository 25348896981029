import RouterConfig from "../router";
import Store from '../store/Store';
import 'react-phone-input-2/lib/style.css'


const App = () => {
  return (
    <Store>
      <RouterConfig />
    </Store>
  )
}

export default App;