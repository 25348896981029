import { PlusCircleOutlined } from '@ant-design/icons';
import { Input, Layout, Modal, Form, Button } from 'antd';
import React, { useState, useContext } from 'react';
import AdminTreeMenu from './AdminTreeMenu';
import MenuItemContent from './MenuItemContent';
import { useEffect } from 'react';
import axios from 'axios';
import { requestHeaders } from '../utils/Utils';
import Loading from '../components/Loading.jsx';
import ModalAlert from "../components/SweetAlert/ModalAlert";
import styled from '@emotion/styled';
import { docURL } from '../helper/APIConfig';
import { Context } from '../store/Store';



const StyledContainer = styled.div`
    .ant-layout-sider{
        min-width: 300px !important;
    }
`


const { Sider, Content } = Layout;

const HelpLayout = () => {
    const [deviceState, dispatch] = useContext(Context);

    const [mainPageForm] = Form.useForm();
    const [subPageForm] = Form.useForm();
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [newMenuItem, setNewMenuItem] = useState('');
    const [menuItems, setMenuItems] = useState([
        // { title: 'Main', children: [ { title: 'subpage 1', children: [ { title: 'Nested sub page 1', children: [] } ] }, { title: 'subpage 2', children: []} ]},
    ]);

    const [isSubMenuDialogVisible, setIsSubMenuDialogVisible] = useState(false);
    const [newSubMenu, setNewSubMenu] = useState('');
    const [currentMenuItem, setCurrentMenuItem] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState({});

    const [documentPageData, setDocumentPageData] = useState({});
    const [parentList, setParentList] = useState({});
    const [pageContentList, setPageContentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertDetails, setAlertDetails] = useState({
        showAlert: false,
        alertType: '',
        alertMsg: ''
    })
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState('Documentation');
    const [isPageEdited, setIsPageEdited] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const { documents = {} } = deviceState ?? {};
    const { isPageEdit } = documents;
    
    useEffect(() => {
        getDocumentData();
    }, []);


    useEffect(() => {
        setIsPageEdited(isPageEdit ?? false);
    }, [deviceState]);

    useEffect(() => {
        if(selectedMenuItem.pageCode){
            
            const result = findPageCode(menuItems, selectedMenuItem.pageCode);
            if(result && Object.keys(result).length) setSelectedMenuItem(result);

        }

    }, [menuItems])

    function findPageCode(data, targetCode) {
        for (const item of data) {
          if (item.pageCode === targetCode) {
            return item; // Found the matching pageCode
          }
      
          // If there are children, recursively search in them
          if (item.children && item.children.length > 0) {
            const result = findPageCode(item.children, targetCode);
            if (result) {
              return result; // Found the pageCode in nested children
            }
          }
        }
      
        return null; // pageCode not found in this branch
      }

    useEffect(() =>{
        // if(Object.keys(parentList)?.length > 0){
        //     setParentList({
        //         ...parentList,
        //         title: menuItems[0].title
        //     })
        // }
    }, [menuItems])

    useEffect(() => {
        const documentList = documentPageData?.documents; 
        if(documentList?.length > 0){
            const sideBarTitles = [];
            const pageContentList = [];

            documentList.forEach(list => {
                const pageCode = list.page.pagecode;
                sideBarTitles.push({title: list.title, pageCode, path: list.page.path});
                pageContentList.push({
                    pageCode,
                    pageContent: list.content,
                    path: list.page.path,
                    title: list.title
                })
            });

            const nestedData = createNestedStructure(sideBarTitles);

            setMenuItems(nestedData);
            setPageContentList(pageContentList);

        }
        else {
            setMenuItems([]);
            setPageContentList([]);
        }

    }, [documentPageData]);

    const createNestedStructure = (data) => {
        const map = {};
        const result = [];
    
        data.forEach(item => {
            const parts = item.pageCode.split('.');
            const title = parts.join('.'); // Full title
            const node = { pageCode: title, title: item.title, path: item.path, children: [] }; // Create new node
    
            map[title] = node; // Store reference in map
    
            // If not top-level, find parent and add this node to its children
            if (parts.length > 1) {
                const parentTitle = parts.slice(0, -1).join('.'); // Construct parent title
                if (map[parentTitle]) {
                    map[parentTitle].children.push(node);
                }
            } else {
                result.push(node); // Top-level items go directly into result
            }
        });
    
        return result;
    };


    const handleDoubleClick = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        setIsEditing(false);
    };

    const handleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSave();
        }
    };
    

    const getDocumentData = () => {
        setLoading(true);
        axios.get(`${docURL}/document/all_documents/?doc_type=zoom`, { ...requestHeaders() })
            .then(response => {
                const { details } = response.data ?? {};
                setDocumentPageData(details);
            })
            .catch(error => {
                console.error(`Error fetching :`, error);
            }).finally(() => {
                setLoading(false);
            })
    }


    const handleOpenDialog = () => {
        console.log('cuv8565', isPageEdited);
        if(!isPageEdited){
            setShowConfirmModal(isPageEdited);
            setIsDialogVisible(true);
            setParentList({});
        }
        else setShowConfirmModal(isPageEdited);
    };

    const handleCloseDialog = () => {
        setIsDialogVisible(false);
        setNewMenuItem('');
        mainPageForm.resetFields();
    };

    const handleSaveMenuItem = () => {
        setMenuItems([...menuItems, { title: newMenuItem, children: [] }]);
        handleCloseDialog();
        saveDocTitle(newMenuItem);
    };


    const handleOpenSubMenuDialog = (menuItem) => {
        // setSelectedMenuItem(menuItem);
        // Check if there are fewer than 3 existing submenus for the current parent menu item
        // if (menuItem.children.length < 3) {
            setCurrentMenuItem(menuItem);
            setIsSubMenuDialogVisible(true);
        // } else {
        //     console.log("test sub")
            // Display a message or notification that you can't add more submenus
        // }
    };

    const handleCloseSubMenuDialog = () => {
        setCurrentMenuItem(null);
        setIsSubMenuDialogVisible(false);
        setNewSubMenu('');
        mainPageForm.resetFields();
        subPageForm.resetFields();
    };

    const handleSaveSubMenu = () => {
        if (currentMenuItem) {
            currentMenuItem.children.push({ title: newSubMenu, children: [] });
            setCurrentMenuItem({ ...currentMenuItem });
        }
        handleCloseSubMenuDialog();
        saveDocTitle(newSubMenu);
    };


    const saveDocTitle = (title) => {
        setLoading(true);
        const payload = {
            "title": title,
            "content": [],
            "user_role": "owner",
            "doc_type": "zoom"
        };

        const url = parentList.pageCode ? `${docURL}/document/sub/?parent_page_code=${parentList.pageCode}` : `${docURL}/document/main`;

        axios.post(url, payload, { ...requestHeaders() })
            .then(response => {
                // setIsEditPage(false);
                // setAlertDetails({ sh})
                const { message , status } = response.data ?? {};
                getDocumentData();
                // setAlertDetails({ showAlert: true, alertType: status, alertMsg: message})
            })
            .catch(error => {
                console.error(`Error fetching :`, error);
                const message = error.response?.data?.message ? error.response.data.message : error.message;
                setAlertDetails({ showAlert: true, alertType: 'Error', alertMsg: message})

            }).finally(() => {
                setLoading(false);
            })
    }

    const handleRemoveSubMenu = (menuItem, submenuTitle) => {                   
        setLoading(true);
        if(menuItem.pageCode){
            axios.delete(`${docURL}/document/delete_document/zoom/${menuItem.pageCode}/owner`, { ...requestHeaders() })
            .then(response => {
                setSelectedMenuItem({});
                // setAlertDetails({ sh})
                const { message , status } = response.data ?? {};
                setAlertDetails({ showAlert: true, alertType: status, alertMsg: message})
            })
            .catch(error => {
                console.error(`Error fetching :`, error);
                const message = error.response?.data?.message ? error.response.data.message : error.message;
                setAlertDetails({ showAlert: true, alertType: 'Error', alertMsg: message})

            }).finally(() => {
                setLoading(false);
            })
        }
    };
    const validateTitle = (_, value) => {
        if (pageContentList.some(item => item.title === value)) {
            return Promise.reject(new Error('This title already exists. Please enter a unique title.'));
        }
        return Promise.resolve();
    };



    return (
        <StyledContainer>
             { loading ? <Loading style={{background: '#fff'}} loading={loading} adjustHeight={150}/> : 
        <Layout style={{ minHeight: '100vh', display: 'flex' }}>

            <Sider width={200} theme="dark">
                <div className="add-button-container" style={{ justifyContent: 'space-between' }}>
                    {/* <Button type="primary" onClick={handleOpenDialog}>
                        Add
                    </Button> */}
                    {/* <div className="editable-title">
                                {isEditing ? (
                                    <Input
                                        value={title}
                                        onChange={handleChange}
                                        onBlur={handleSave}
                                        onPressEnter={handleSave}
                                        autoFocus
                                        className="title-input"
                                    />
                                ) : (
                                    <span 
                                        className="title" 
                                        onClick={handleDoubleClick}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {title}
                                    </span>
                                )}
                        </div>   */}
                        <span 
                                        className="title" 
                                        // onClick={handleDoubleClick}
                                        // style={{ cursor: 'pointer' }}
                                    >
                                        {title}
                                    </span>
                          <PlusCircleOutlined
                        className="add-sub-icon add-sub-button"
                        onClick={handleOpenDialog}

                    />
                </div>
                {menuItems &&
                <AdminTreeMenu
                    showConfirmModal={showConfirmModal}
                    menuItems={menuItems}
                    key={menuItems.length}
                    onSubMenuClick={handleOpenSubMenuDialog}
                    onRemoveSubMenu={handleRemoveSubMenu}
                    onSelectMenuItem={setSelectedMenuItem} // Pass the setSelectedMenuItem function
                    setUpdateParentList={setParentList}
                    updateModalStatus={(value) => {
                        console.log('cuv7655', value);
                        setShowConfirmModal(value);
                    }}
                />
}
            </Sider>


            <Layout>
                <Content>
                    <div style={{ padding: 24, background: '#fff', minHeight: '100vh' }}>
                        {/* {JSON.stringify(selectedMenuItem)} */}
                        {selectedMenuItem.title ? (
                            <MenuItemContent menuItem={selectedMenuItem} keyMenu={selectedMenuItem.title} key={parentList.pageCode} parentList={parentList} contentList={pageContentList} loadingFn={setLoading} setAlertDetails={setAlertDetails} />
                        ) : (
                            <h1></h1>
                        )}
                    </div>
                </Content>
            </Layout>
            {/* <Modal
                title="Add Page Name"
                visible={isDialogVisible}
                onOk={handleSaveMenuItem}
                onCancel={handleCloseDialog}
            >
                <Input
                    placeholder="Enter Page Name"
                    value={newMenuItem}
                    onChange={(e) => setNewMenuItem(e.target.value)}
                />
            </Modal> */}

<Modal
            title="Add Page Name"
            visible={isDialogVisible}
            footer={null}  // Hide default footer buttons
            onCancel={handleCloseDialog}
        >
            <Form onFinish={handleSaveMenuItem} form={mainPageForm}>
                <Form.Item
                    label="Page Name"
                    name="pageName"
                    rules={[
                        { required: true, message: 'Please enter a page name.' },
                        { validator: validateTitle }
                    ]}
                >
                    <Input
                        placeholder="Enter Page Name"
                        value={newMenuItem}
                        onChange={(e) => setNewMenuItem(e.target.value)}
                    />
                </Form.Item>

                {/* Custom buttons */}
                <Form.Item style={{ textAlign: 'right' }}>
                    <Button onClick={handleCloseDialog} style={{ marginRight: '8px' }}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Create
                    </Button>
                </Form.Item>
            </Form>
        </Modal>

            <Modal
                title="Add Sub Page"
                visible={isSubMenuDialogVisible}
                // onOk={handleSaveSubMenu}
                footer={null}
                onCancel={handleCloseSubMenuDialog}
            >
                <Form onFinish={handleSaveSubMenu} form={subPageForm}>
                    <Form.Item
                        label="Subpage Name"
                        name="subPage"
                        rules={[
                            { required: true, message: 'Please enter a sub page title.' },
                            { validator: validateTitle }
                        ]}
                            >
                        <Input
                            placeholder="Enter sub page"
                            value={newSubMenu}
                            onChange={(e) => setNewSubMenu(e.target.value)}
                        />
                
                </Form.Item>

                    <Form.Item className="d-flex justify-content-end">
                        <Button onClick={handleCloseSubMenuDialog}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: '8px' }}>
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

          { alertDetails.showAlert  &&  <ModalAlert {...alertDetails} onConfirmHandler={() => { setAlertDetails({ ...alertDetails, showAlert: false }); getDocumentData(); }} /> }

        </Layout>
}
        </StyledContainer>
    );
};


export default HelpLayout;
