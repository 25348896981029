import React from "react";
import { Popconfirm } from 'antd';


const ConfirmationAlert = ({children, title='Are you sure you want to delete it ?', placement='left', action}) => {
    return (
        <Popconfirm
            title={title}
            okText="Yes"
            cancelText="No"
            placement={placement}
            onConfirm={action}
        >
            {children}
        </Popconfirm>
    )

}

export default ConfirmationAlert;