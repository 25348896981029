import React, { useState } from 'react';
import { Modal } from 'antd'; // Ensure you have the antd library installed

const CommonModal = ({ children, title, width, isMeetingModal, setIsMeetingModal, onHandleCancel = () => {}, footer = null }) => {
    return (
        <Modal
            title={title}
            centered
            open={isMeetingModal}
            width={width ? width : 590}
            onCancel={() => { setIsMeetingModal(false); onHandleCancel();}}
            footer={footer}
            maskClosable={false}
        >
            {children}
        </Modal>
    );
};

export default CommonModal;
