import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import AdminContainers from '../layout/AdminLayout';
import { getSubscriptionDetails } from '../utils/Utils';


const Loading = () => <></>;

const MyLoadable = (component) =>  
  Loadable({
    loader: component,      
    loading: Loading,
  });
      

const RouterList = () => {
     useEffect(() => {
        const storedColors = JSON.parse(sessionStorage.getItem('themeColors'));
        if (storedColors) {
          Object.keys(storedColors).forEach(colorKey => {
            document.documentElement.style.setProperty(`--${colorKey}`, storedColors[colorKey]);
          });
        }
        else {
          document.documentElement.style.setProperty(`--primary-color`, '#007aa8');
          document.documentElement.style.setProperty(`--secondary-color`, '#24aedf');
        }
      }, []);
  
  const subscriptionList = getSubscriptionDetails();

  const renderRoutes = () => {
    const routes = [];


    // Add administation routes
    routes.push(<Route exact path='/profile' component={MyLoadable(() => import('../pages/settings/MyProfile'))} />);
    routes.push(<Route exact path='/organizations' component={MyLoadable(() => import('../pages/settings/Organization/Organizations'))} />);
    routes.push(<Route exact path='/users' component  ={MyLoadable(() => import('../pages/settings/AllUsers'))} />);
    routes.push(<Route exact path='/system-status' component={MyLoadable(() => import('../pages/settings/SystemStatus'))} />);
    
    // Add Marketplace routes
    routes.push(<Route exact path='/market-place' component={MyLoadable(() => import('../pages/Marketing/MarketPlace'))} />);
    routes.push(<Route exact path='/subscription' component={MyLoadable(() => import('../pages/Marketing/Subscription'))} />);
    
    // Add Accounts routes
    routes.push(<Route exact path='/account-settings' component={MyLoadable(() => import('../pages/administation/AccountSettings'))} />);
    routes.push(<Route exact path='/application-status' component={MyLoadable(() => import('../pages/administation/ApplicationStatus'))} />);
    routes.push(<Route exact path='/manage-videos' component={MyLoadable(() => import('../pages/administation/ManageVideos'))} />);
    routes.push(<Route exact  path='/admin-dashboard' component={MyLoadable(() => import('../pages/administation/AdminDashboard'))} />);
    routes.push(<Route exact  path='/zoom-accounts' component={MyLoadable(() => import('../pages/administation/Account'))} />);
    routes.push(<Route exact  path='/zoom-logs' component={MyLoadable(() => import('../pages/administation/ZoomLogs'))} />);



    // // Add Test routes
    // routes.push(<Route exact path='/test' component={MyLoadable(() => import('../components/TestComponent'))} />);
    
    // Add default Application Routes
    routes.push(<Route exact path='/home' component={MyLoadable(() => import('../pages/Home'))} />);
    routes.push(<Route exact path='/help-menu' component={MyLoadable(() => import('../pages/HelpMenu'))} />);
    routes.push(<Route exact path='/privacy-terms' component={MyLoadable(() => import('../pages/PrivacyandTerms'))} />);
    routes.push(<Route exact path='/about-us' component={MyLoadable(() => import('../pages/AboutUs'))} />);
    routes.push(<Route exact path='/ticket' component={MyLoadable(() => import('../pages/help/Ticket'))} />);
    routes.push(<Route exact path='/videos' component={MyLoadable(() => import('../pages/help/ProductVideos'))} />);

    // Conditionally render CPE routes
    if (subscriptionList.includes('svb')) {
      routes.push(
        <React.Fragment key="cpe-routes">
           { /* Devices */} 
                  <Route exact path='/cpe-devices-dashboard' component={MyLoadable(() => import('../CPEManageDashboard/CPEDevicesDashboard'))} />
                  <Route exact path='/cpe-devices-onboarding' component={MyLoadable(() => import('../CPEManageDashboard/devices/CPEDeviceOnBoarding'))} />
                  <Route exact path='/cpe-list-devices' component={MyLoadable(() => import('../CPEManageDashboard/devices/CPEListDevices'))} />
                  <Route exact path='/cpe-device-details/:deviceId' component={MyLoadable(() => import('../CPEManageDashboard/devices/CPEDeviceDetails'))} />
                  <Route exact path='/cpe-device-update/:deviceId' component={MyLoadable(() => import('../CPEManageDashboard/devices/CPEDeviceOnBoarding'))} />
                  <Route exact path='/cpe-reports' component={MyLoadable(() => import('../CPEManageDashboard/devices/CPEReports'))} />

                  {/* Connect Grid */}

                  <Route exact path='/connect-grid' component={MyLoadable(() => import('../CPEManageDashboard/devices/NetworkVisual/CPENetworkVisualization'))} />

                  { /* Images */}
                  <Route exact path='/cpe-supported-apps' component={MyLoadable(() => import('../CPEManageDashboard/images/AvailableImages'))} />
                  <Route exact path='/cpe-deivice-apps' component={MyLoadable(() => import('../CPEManageDashboard/images/DeviceImages'))} />

                  {/* Administration */}
                  <Route exact path='/shell' component={MyLoadable(() => import('../CPEManageDashboard/administration/Shell'))} />

                  {/* Auditing */}
                  <Route exact path='/device-logs' component={MyLoadable(() => import('../CPEManageDashboard/auditing/DeviceLogs'))} />
                  <Route exact path='/app-reports' component={MyLoadable(() => import('../CPEManageDashboard/auditing/AppReports'))} />


                  {/* Analytics */}
                  <Route exact path='/dashboard-analytics' component={MyLoadable(() => import('../analytics/dashboard-analytics'))} />
                  <Route exact path='/app-analytics' component={MyLoadable(() => import('../analytics/app-analytics'))} />
                  <Route exact path='/user-analytics' component={MyLoadable(() => import('../analytics/user-analytics'))} />
                  <Route exact path='/site-analytics' component={MyLoadable(() => import('../analytics/site-analytics'))} />
                  <Route exact path='/user-details' component={MyLoadable(() => import('../analytics/user-details'))} />
                  <Route exact path='/dns-analytics' component={MyLoadable(() => import('../analytics/dns-analytics'))} />
        </React.Fragment>
      );
    }

    // Conditionally render SD-WAN routes
    if (subscriptionList.includes('sdwan')) {
      // routes.push(<Route exact path='/sdwan' component={MyLoadable(() => import('../pages/SDWANDashboard'))} />)
        routes.push(
          <React.Fragment key="sdwan-routes">
              <Route exact path='/sdwan' component={MyLoadable(() => import('../pages/SDWANDashboard'))} />
                  <Route exact path='/access-token' component={MyLoadable(() => import('../pages/settings/AccessTokens'))} />
                  <Route exact path='/cne' component={MyLoadable(() => import('../pages/connections/Devices'))} />
                  <Route exact path='/device-details/:deviceId' component={MyLoadable(() => import('../pages/connections/DeviceDetails'))} />
                  <Route exact path='/virtual-links' component={MyLoadable(() => import('../pages/connections/Tunnels'))} />
                  <Route exact path='/app-signatures' component={MyLoadable(() => import('../pages/connections/AppList'))} />
                  <Route exact path='/devices' component={MyLoadable(() => import('../pages/connections/Devices'))} />
                  {/* <Route exact path='/device-details/:deviceId' component={MyLoadable(() => import('../pages/connections/DeviceDetails'))} /> */}
                  <Route exact path='/tunnels' component={MyLoadable(() => import('../pages/connections/Tunnels'))} />
                  <Route exact path='/app-identify' component={MyLoadable(() => import('../pages/connections/AppList'))} />
                  <Route exact path='/app-identify-details/:appIds' component={MyLoadable(() => import('../pages/connections/AddEditApp'))} />
                  <Route exact path='/app-identify-details' component={MyLoadable(() => import('../pages/connections/AddEditApp'))} />
                  <Route exact path='/device-token' component={MyLoadable(() => import('../pages/connections/DeviceToken'))} />
                  <Route exact path='/jobs' component={MyLoadable(() => import('../pages/history/Jobs'))} />
                  <Route exact path='/notifications' component={MyLoadable(() => import('../pages/history/Notifications'))} />
                  <Route exact path='/routing-labels' component={MyLoadable(() => import('../pages/connections/PathTags'))} />
                  <Route exact path='/path-tags' component={MyLoadable(() => import('../pages/connections/PathTags'))} />
                  <Route exact path='/tunnel-graph' component={MyLoadable(() => import('../pages/analytics/TunnelGraph'))} />
                  <Route exact path='/device-location' component={MyLoadable(() => import('../pages/analytics/DeviceLocation'))} />
                  <Route exact path='/firewalls' component={MyLoadable(() => import('../pages/security/Firewall'))} />
                  <Route exact path='/firewall-details/:firewallId' component={MyLoadable(() => import('../pages/security/AddEditFirewall'))} />
                  <Route exact path='/firewall-details' component={MyLoadable(() => import('../pages/security/AddEditFirewall'))} />

                  <Route exact path='/available' component={MyLoadable(() => import('../pages/appstore/Available'))} />
                  <Route exact path='/available-device-details' component={MyLoadable(() => import('../pages/appstore/AvailableDeviceDetails'))} />

                  <Route exact path='/installed' component={MyLoadable(() => import('../pages/appstore/Installed'))} />
                  <Route exact path='/installed-device-details' component={MyLoadable(() => import('../pages/appstore/InstalledDeviceDetails'))} />

                  <Route exact path='/qos-policies' component={MyLoadable(() => import('../pages/trafficOptimization/QosPolicy'))} />
                  <Route exact path='/qos-policy-details/:policyId' component={MyLoadable(() => import('../pages/trafficOptimization/AddEditQosPolicy'))} />
                  <Route exact path='/qos-policy-details' component={MyLoadable(() => import('../pages/trafficOptimization/AddEditQosPolicy'))} />
                  <Route exact path='/qos-traffic' component={MyLoadable(() => import('../pages/trafficOptimization/QosTrafficMap'))} />
                  <Route exact path='/peers' component={MyLoadable(() => import('../pages/connections/Peers'))} />
                  <Route exact path='/peer-details/:peerId' component={MyLoadable(() => import('../pages/connections/AddEditPeer'))} />
                  <Route exact path='/peer-details' component={MyLoadable(() => import('../pages/connections/AddEditPeer'))} />

                  <Route exact path='/path-selections' component={MyLoadable(() => import('../pages/trafficOptimization/PathSelection'))} />
                  <Route exact path='/policy-details/:policyId' component={MyLoadable(() => import('../pages/trafficOptimization/AddEditPolicy'))} />
                  <Route exact path='/policy-details' component={MyLoadable(() => import('../pages/trafficOptimization/AddEditPolicy'))} />
                  <Route exact path='/version' component={MyLoadable(() => import('../pages/Versions'))} />
                  <Route exact path='/doc' component={MyLoadable(() => import('../pages/Document'))} />
                  <Route exact path='/help' component={MyLoadable(() => import('../pages/Help'))} />
                  <Route exact path='/ip' component={MyLoadable(() => import('../pages/ip/IpManagement'))} /> 
                  <Route exact path='/network-traffic' component={MyLoadable(() => import('../pages/NetworkTraffic'))} />
                  <Route exact path='/quick-help' component={MyLoadable(() => import('../pages/help/QuickHelp'))} />
                  <Route exact path='/chat' component={MyLoadable(() => import('../pages/help/Chat'))} />
                  <Route exact path='/sdwan-reports' component={MyLoadable(() => import('../pages/reports/Reports'))} />
                  <Route exact path='/reports/firewall-history' component={MyLoadable(() => import('../pages/reports/FirewallHistory'))} />
                  <Route exact path='/network-dashboard' component={MyLoadable(() => import('../pages/network-dashboard/NetworkDashboard'))} />
                  <Route exact path='/reports/policy-status' component={MyLoadable(() => import('../pages/reports/PolicyStatus'))} />
          </React.Fragment>
        );
    }

    // Conditionally render zoom routes   
    if(subscriptionList.includes('zoom')){
        routes.push(
          <React.Fragment key="zoom-routes">         
              <Route exact path='/zoom-dashboard' component={MyLoadable(() => import('../pages/Zoom/ZoomDashboard'))} />
              <Route exact path='/zoom-users' component={MyLoadable(() => import('../pages/Zoom/UserManagement'))} />
              <Route exact path='/zoom-meetings' component={MyLoadable(() => import('../pages/Zoom/MeetingManagement'))} />
              <Route exact path='/zoom-webinars' component={MyLoadable(() => import('../pages/Zoom/WebinarManagement'))} />
              <Route exact path='/zoom-reports' component={MyLoadable(() => import('../pages/Zoom/ZoomReports'))} />
              <Route exact path='/zoom-recordings' component={MyLoadable(() => import('../pages/Zoom/ZoomRecordings'))} />
              <Route exact path='/zoom-calender' component={MyLoadable(() => import('../pages/Zoom/ZoomCalender'))} />
         </React.Fragment>
        )
      }
    // routes.push(<Route key="page404" component={Page404} />)
       
    

    return routes;
  };

  const Page404 = (props) => {
    return (
      <div style={{ padding: "1rem" }}>
        <h2 style={{ fontWeight: "bolder" }}>Error 404 </h2>
        <p>The page you’re looking for was not found. </p>
      </div>
    )
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={MyLoadable(() => import('../pages/authentication/SignInRegisteration'))} />
        <Route exact path='/login' component={MyLoadable(() => import('../pages/authentication/Login'))} />
        <Route exact path='/reset-password' component={MyLoadable(() => import('../pages/ResetPassword'))} />
        <Route exact path='/forgot-password' component={MyLoadable(() => import('../pages/ForgotPassword'))} />
        <Route exact path='/register-customer' component={MyLoadable(() => import('../pages/authentication/Register'))} />
        <Route exact path='/verify-email' component={MyLoadable(() => import('../pages/authentication/Otp'))} />
        <Route exact path='/test' component={MyLoadable(() => import('../components/TestComponent'))} />
        <Route exact path='/validate-email' component={MyLoadable(() => import('../pages/authentication/Email'))} />
        

        <Route
  render={({ history, location }) => {
    // Function to check if any route or its children match the pathname
    const isRouteMatched = (routes) => {
      for (let i = 0; i < routes.length; i++) {
        const route = routes[i];
        // Check if the current route matches the pathname
        if (route.props.path === location.pathname) {
          return true;
        }
        // Check if the current route has parameters and matches the pathname
        if (route.props.path && route.props.path.includes(':')) {
          const pathSegments = route.props.path.split('/');
          const locationSegments = location.pathname.split('/');
          if (pathSegments.length === locationSegments.length) {
            let matched = true;
            for (let j = 0; j < pathSegments.length; j++) {
              if (pathSegments[j].startsWith(':')) continue;
              if (pathSegments[j] !== locationSegments[j]) {
                matched = false;
                break;
              }
            }
            if (matched) return true;
          }
        }
        // Check if the current route has children
        if (route.props.children) {
          // Recursively check if any children match the pathname
          if (isRouteMatched(React.Children.toArray(route.props.children))) {
            return true;
          }
        }
      }
      return false;
    };

    // Check if any route or its children match the pathname
    const isMatched = isRouteMatched(renderRoutes());

    console.log("Location:", location, isMatched, renderRoutes());

    // Render AdminContainers with matched routes or 404 component
    return (
      <AdminContainers history={history} location={location}>
        {/* Render matched route and its children only */}
        {isMatched ? (
          renderRoutes().filter((route) => {
            // Check if the current route or its children match the pathname
            if (
              route.props.path === location.pathname ||
              (route.props.children &&
                isRouteMatched(React.Children.toArray(route.props.children)))
            ) {
              return true;
            }
            return false;
          })
        ) : (
          <Page404 />
        )}
      </AdminContainers>
    );  
  }}
/>


      </Switch>
    </BrowserRouter>  
  );
};

export default RouterList;
