import * as Icon from "react-feather";
import { AiOutlineApartment, AiOutlineClockCircle } from "react-icons/ai";
import { BsHddNetworkFill } from "react-icons/bs";
import { FaKey, FaTabletAlt, FaRoute, FaFileAlt, FaTicketAlt, FaNetworkWired, FaListAlt, FaImages, FaHome } from "react-icons/fa";
import { SiGraphql, SiWire, SiCoinmarketcap } from "react-icons/si";
import { BsBell, BsShieldLockFill } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";
import { RiDashboardFill, RiAdminLine, RiStackFill } from 'react-icons/ri';
import { MdOutlineEventAvailable, MdOutlineDevices, MdOutlineApps, MdVideoLibrary } from "react-icons/md";
import { SiPowershell } from 'react-icons/si';
import { AiOutlineAudit } from 'react-icons/ai';
import React from "react";

const sidebarList = [
    {
        id: "1",
        title: "Home",
        type: "item",
        icon: <Icon.Home size={20} />,
        permissions: ["admin", "editor"],
        order: 1,
        navLink: "/home",
        redirectExternal: false
    },
    {
        id: "administration",
        title: "Administration",
        order: 2,
        type: "double-collapse",
        icon: <MdManageAccounts size={20} />,
        children: [
                // {
                //     id: "zoom_admin",
                //     title: "Zoom",
                //     type: "collapse",
                //     order: 3,
                //     icon: <MdManageAccounts size={20} />,
                //     children: [
                //         {
                //             id: "admin-dashboard",
                //             title: "Dashboard",
                //             type: "item",
                //             icon: <Icon.User size={16} />,
                //             permissions: ["admin", "editor"],
                //             navLink: "/admin-dashboard"
                //         },
                //         {
                //             id: "2",
                //             title: "Accounts",
                //             type: "item",
                //             icon: <Icon.User size={16} />,
                //             permissions: ["admin", "editor"],
                //             navLink: "/zoom-accounts"
                //         },
                //         {
                //             id: "audit",
                //             title: "Audit",
                //             type: "item",
                //             icon: <Icon.User size={16} />,
                //             permissions: ["admin", "editor"],
                //             navLink: "/zoom-logs"
                //         },
                //     ]
                // },
            {
                id: "2",
                title: "Profile",
                type: "item",
                icon: <Icon.User size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/profile"
            },
            {
                id: "4",
                title: "Organizations",
                type: "item",
                icon: <AiOutlineApartment size={17} />,
                permissions: ["admin", "editor"],
                navLink: "/organizations"
            },
            {
                id: "5",
                title: "Users",
                type: "item",
                icon: <Icon.Users size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/users"
            },
            {
                id: "system_status",
                title: "System Status",
                type: "item",
                icon: <Icon.Users size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/system-status"
            },
        ]
    },
    {
        id: "sub1",
        title: "Accounts",
        type: "double-collapse",
        order: 3,
        icon: <MdManageAccounts size={20} />,
        children: [
            {
                id: "service_box",
                title: "Service Box",
                type: "collapse",
                order: 3,
                icon: <MdManageAccounts size={20} />,
                children: [
                    {
                        id: "3",
                        title: "Manage Videos",
                        type: "item",
                        icon: <Icon.User size={16} />,
                        permissions: ["admin", "editor"],
                        navLink: "/manage-videos"
                    },
                ]
            },
            {
                id: "23",
                title: "Application Status",
                type: "item",
                icon: <Icon.User size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/application-status"
            },
            {
                id: "documents",
                title: "Manage Documents",
                type: "item",
                // icon: <FaFileAlt size={20} className="ant-menu-item-icon" />,
                permissions: ["admin", "editor"],
                navLink: "/help-menu",
                redirectExternal: true,
                // showSubIcon: true
            },
        ]
    },
    {
        id: "marketplace",
        title: "Market Place",
        type: "item",
        order: 4,
        icon: <SiCoinmarketcap size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/market-place",
        redirectExternal: false
    },
    // {
    //     id: "ticket",
    //     title: "Tickets",
    //     type: "item",
    //     icon: <FaTicketAlt size={20} />,
    //     permissions: ["admin", "editor"],
    //     order: 8,
    //     navLink: "/ticket"
    // },

    {
        id: "13",
        title: "About Us",
        type: "item",
        icon: <Icon.Info size={20} />,
        permissions: ["admin", "editor"],
        order: 9,
        navLink: "/about-us",
        redirectExternal: false
    },
    {
        id: "privacy",
        title: "Privacy & Terms",
        type: "item",
        icon: <Icon.Info size={20} />,
        permissions: ["admin", "editor"],
        order: 11,
        navLink: "/privacy-terms",
        redirectExternal: false
    },
    {
        id: "help",
        title: "Help",
        type: "collapse",
        icon: <Icon.Info size={20} />,
        permissions: ["admin", "editor"],
        order: 10,
        children: [
            {
                id: "ticket",
                title: "Tickets",
                type: "item",
                icon: <FaTicketAlt size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/ticket",
                showSubIcon: true
            },
            {
                id: "documents",
                title: "Documents",
                type: "item",
                icon: <FaFileAlt size={20} className="ant-menu-item-icon" />,
                permissions: ["admin", "editor"],
                navLink: "/help-menu",
                redirectExternal: true,
                showSubIcon: true
            },
            {
                id: "videos",
                title: "Videos",
                type: "item",
                icon: <MdVideoLibrary size={20} />, 
                permissions: ["admin", "editor"],
                navLink: "/videos",
                showSubIcon: true
            }
        ]
    }
];

const NewUserLists = [
    {
        id: "home",
        title: "Home",
        type: "item",
        icon: <Icon.Home size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/home",
        order: 1,
        redirectExternal: false
    },
    {
        id: "administration",
        title: "Administration",
        order: 2,
        type: "double-collapse",
        icon: <MdManageAccounts size={20} />,
        children: [
            // {
            //     id: "zoom_admin",
            //     title: "Zoom",
            //     type: "collapse",
            //     order: 3,
            //     icon: <MdManageAccounts size={20} />,
            //     children: [
            //         {
            //             id: "admin-dashboard",
            //             title: "Dashboard",
            //             type: "item",
            //             icon: <Icon.User size={16} />,
            //             permissions: ["admin", "editor"],
            //             navLink: "/admin-dashboard"
            //         },
            //         {
            //             id: "2",
            //             title: "Accounts",
            //             type: "item",
            //             icon: <Icon.User size={16} />,
            //             permissions: ["admin", "editor"],
            //             navLink: "/zoom-accounts"
            //         },
            //         {
            //             id: "audit",
            //             title: "Audit",
            //             type: "item",
            //             icon: <Icon.User size={16} />,
            //             permissions: ["admin", "editor"],
            //             navLink: "/zoom-logs"
            //         }
            //     ]
            // },
            {
                id: "2",
                title: "Profile",
                type: "item",
                icon: <Icon.User size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/profile"
            },
            {
                id: "4",
                title: "Organizations", 
                type: "item",
                icon: <AiOutlineApartment size={17} />,
                permissions: ["admin", "editor"],
                navLink: "/organizations"
            },
            {
                id: "5",
                title: "Users",
                type: "item",
                icon: <Icon.Users size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/users"
            },
            {
                id: "system_status",
                title: "System Status",
                type: "item",
                icon: <Icon.Users size={16} />,
                permissions: ["admin", "editor"],
                navLink: "/system-status"
            },
        ]
    },
    {
        id: "marketplace",
        title: "Market Place",
        type: "item",
        icon: <SiCoinmarketcap size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/market-place",
        order: 2,
        redirectExternal: false
    },
    // {
    //     id: "ticket",
    //     title: "Tickets",
    //     type: "item",
    //     icon: <FaTicketAlt size={20} />,
    //     permissions: ["admin", "editor"],
    //     order: 8,
    //     navLink: "/ticket"
    // },
    {
        id: "aboutus",
        title: "About Us",
        type: "item",
        icon: <Icon.Info size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/about-us",
        order: 9,
        redirectExternal: false
    },
    {
        id: "privacy",
        title: "Privacy & Terms",
        type: "item",
        icon: <Icon.Info size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/privacy-terms",
        order: 11,
        redirectExternal: false
    },
    {
        id: "help",
        title: "Help",
        type: "collapse",
        icon: <Icon.Info size={20} />,
        permissions: ["admin", "editor"],
        order: 10,
        children: [
            {
                id: "ticket",
                title: "Tickets",
                type: "item",
                icon: <FaTicketAlt size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/ticket",
                showSubIcon: true
            },
            {
                id: "documents",
                title: "Documents",
                type: "item",
                icon: <FaFileAlt size={20} className="ant-menu-item-icon" />,
                permissions: ["admin", "editor"],
                navLink: "/help-menu",
                redirectExternal: true,
                showSubIcon: true
            },
            {
                id: "videos",
                title: "Videos",
                type: "item",
                icon: <MdVideoLibrary size={20} />, 
                permissions: ["admin", "editor"],
                navLink: "/videos",
                showSubIcon: true
            },
        ]
    },
];


const singleVirtualboxMenu = [
    {
        id: "cpe",
        title: "SVB",
        type: "double-collapse",
        order: 6,
        icon: <RiDashboardFill size={20} />,
        children: [
            {
                id: "cpe-devices-dashboard",
                title: "Dashboard",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/cpe-devices-dashboard"
            },
            {
                id: "cpe-devices",
                title: "Devices",
                type: "collapse",
                icon: <FaTabletAlt size={20} />,
                children: [
                    {
                        id: "cpe-devices-onboarding",
                        title: "On Boarding",
                        type: "item",
                        icon: <FaKey size={15} />,
                        permissions: ["admin", "editor"],
                        navLink: "/cpe-devices-onboarding"
                    },
                    {
                        id: "cpe-list-devices",
                        title: "List Devices",
                        type: "item",
                        icon: <FaListAlt size={15} />,
                        permissions: ["admin", "editor"],
                        navLink: "/cpe-list-devices"
                    },
                ]
            },
            {
                id: "connect-grid",
                title: "Connect Grid",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/connect-grid"
            },
            {
                id: "cpe-apps",
                title: "Applications",
                type: "collapse",
                icon: <FaImages size={20} />,
                children: [
                    {
                        id: "cpe-supported-apps",
                        title: "Supported Applications",
                        type: "item",
                        icon: <MdOutlineEventAvailable size={15} />,
                        permissions: ["admin", "editor"],
                        tooltipTitle: "Supported Applications",
                        navLink: "/cpe-supported-apps"
                    },
                    {
                        id: "cpe-device-apps",
                        title: "Device Applications",
                        type: "item",
                        icon: <MdOutlineDevices size={15} />,
                        permissions: ["admin", "editor"],
                        tooltipTitle: "Device Apps",
                        navLink: "/cpe-deivice-apps"
                    },
                ]
            },
            {
                id: "admin",
                title: "Administration",
                type: "collapse",
                icon: <RiAdminLine size={20} fill={"rgb(245, 245, 245)"} />,
                children: [
                    {
                        id: "admin-shell",
                        title: "Shell",
                        type: "item",
                        icon: <SiPowershell size={16} />,
                        permissions: ["admin", "editor"],
                        navLink: "/shell"
                    },
                ]
            },
            {
                id: "advanced-analytics",
                title: "Data Analytics",
                type: "collapse",
                icon: <RiDashboardFill size={20} />,
                children: [
                    {
                        id: "1",
                        title: "Summary",
                        type: "item",
                        icon: <Icon.Activity size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/dashboard-analytics"
                    },
                    {
                        id: "2",
                        title: "App Analytics",
                        type: "item",
                        icon: <Icon.Activity size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/app-analytics"
                    },
                    {
                        id: "3",
                        title: "User Analytics",
                        type: "item",
                        icon: <Icon.Activity size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/user-analytics"
                    },
                    {
                        id: "4",
                        title: "Site Analytics",
                        type: "item",
                        icon: <Icon.Activity size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/site-analytics"
                    },
                    {
                        id: "5",
                        title: "User Details",
                        type: "item",
                        icon: <Icon.Activity size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/user-details"
                    },
                    {
                        id: "6",
                        title: "DNS Analytics",
                        type: "item",
                        icon: <Icon.Activity size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/dns-analytics"
                    }

                ]
            },
            {
                id: "auditing",
                title: "Auditing",
                type: "collapse",
                icon: <AiOutlineAudit size={20} fill={"rgb(245, 245, 245)"} />,
                children: [
                    {
                        id: "device-logs",
                        title: "Device Logs",
                        type: "item",
                        icon: <RiStackFill size={16} />,
                        permissions: ["admin", "editor"],
                        navLink: "/device-logs"
                    },
                    {
                        id: "ip",
                        title: "IP Management",
                        type: "item",
                        icon: <FaNetworkWired size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/ip",
                        redirectExternal: false
                    },
                    {
                        id: "app-reports",
                        title: "App Reports",
                        type: "item",
                        permissions: ["admin", "editor"],
                        navLink: "/app-reports",
                        redirectExternal: false
                    },
                ]
            },
            {
                id: "cpe-reports",
                title: "Reports",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/cpe-reports"
            },
        ],

    },
];

const sdwanMenu = [
    {
        id: "sdwan",
        title: "SDWAN",
        type: "double-collapse",
        order: 7,
        icon: <RiDashboardFill size={20} />,
        children: [
            // {
            //     id: "2",
            //     title: "NetworkDashboard",
            //     type: "item",
            //     icon: <BsHddNetworkFill size={20} />,
            //     permissions: ["admin", "editor"],
            //     navLink: "/network-dashboard",
            //     redirectExternal: false
            // },
            {
                id: "1",
                title: "Dashboard",
                type: "item",
                icon: <Icon.Home size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/sdwan",
                redirectExternal: false
            },
            {
                id: "sub2",
                title: "Connections",
                type: "collapse",
                icon: <Icon.Link size={20} />,
                children: [
                    {
                        id: "5",
                        title: "CNE",
                        type: "item",
                        icon: <FaTabletAlt size={15} />,
                        permissions: ["admin", "editor"],
                        navLink: "/cne"
                    },
                    {
                        id: "6",
                        title: "Virtual Links",
                        type: "item",
                        icon: <SiWire size={15} />,
                        permissions: ["admin", "editor"],
                        navLink: "/virtual-links"
                    },
                    // {
                    //     id: "61",
                    //     title: "Peers",
                    //     type: "item",
                    //     icon: <SiWire size={15} />,
                    //     permissions: ["admin", "editor"],
                    //     navLink: "/peers"
                    // },
                    {
                        id: "16",
                        title: "Routing Labels",
                        type: "item",
                        icon: <SiWire size={15} />,
                        permissions: ["admin", "editor"],
                        navLink: "/routing-labels"
                    },
                    {
                        id: "8",
                        title: "App Signatures",
                        type: "item",
                        icon: <FaKey size={15} />,
                        permissions: ["admin", "editor"],
                        navLink: "/app-signatures"
                    }
                ]
            },
            {
                id: "Traffic Optimization",
                title: "Traffic Optimization",
                type: "collapse",
                icon: <BsShieldLockFill size={20} />,
                children: [
                    {
                        id: "17",
                        title: "Path Selection",
                        type: "item",
                        icon: <Icon.Layers size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/path-selections"
                    },
                    {
                        id: "18",
                        title: "QOS Policies",
                        type: "item",
                        icon: <Icon.Layers size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/qos-policies"
                    },
                ]
            },
            {
                id: "sub6",
                title: "Security",
                type: "collapse",
                icon: <BsShieldLockFill size={20} />,
                children: [
                    {
                        id: "17",
                        title: "Firewall",
                        type: "item",
                        icon: <Icon.Layers size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/firewalls"
                    },
                    {
                        id: "15",
                        title: "Access Token",
                        type: "item",
                        icon: <FaKey size={15} />,
                        permissions: ["admin", "editor"],
                        navLink: "/access-token"
                    },
                    {
                        id: "7",
                        title: "Device Token",
                        type: "item",
                        icon: <FaKey size={15} />,
                        permissions: ["admin", "editor"],
                        navLink: "/device-token"
                    },

                ]
            },
            // {
            //     id: "sub99",
            //     title: "App Store",
            //     type: "collapse",
            //     icon: <BsShieldLockFill size={20} />,
            //     children: [
            //         {
            //             id: "66",
            //             title: "Installed",
            //             type: "item",
            //             icon: <Icon.Layers size={20} />,
            //             permissions: ["admin", "editor"],
            //             navLink: "/installed"
            //         },
            //         {
            //             id: "44",
            //             title: "Available",
            //             type: "item",
            //             icon: <FaKey size={15} />,
            //             permissions: ["admin", "editor"],
            //             navLink: "/available"
            //         },

            //     ]
            // },

            {
                id: "sub5",
                title: "History",
                type: "collapse",
                icon: <AiOutlineClockCircle size={20} />,
                children: [
                    {
                        id: "9",
                        title: "Jobs",
                        type: "item",
                        icon: <Icon.Layers size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/jobs"
                    },
                    {
                        id: "10",
                        title: "Notifications",
                        type: "item",
                        icon: <BsBell size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/notifications"
                    },
                ]
            },


            {
                id: "sub4",
                title: "Analytics",
                type: "collapse",
                icon: <Icon.Activity size={20} />,
                children: [
                    {
                        id: "11",
                        title: "Tunnel Graph",
                        type: "item",
                        icon: <SiGraphql size={17} />,
                        permissions: ["admin", "editor"],
                        navLink: "/tunnel-graph"
                    },
                    {
                        id: "12",
                        title: "Device Location",
                        type: "item",
                        icon: <FaRoute size={16} />,
                        permissions: ["admin", "editor"],
                        navLink: "/device-location"
                    }, {
                        id: "16",
                        title: "Network Traffic",
                        type: "item",
                        icon: <Icon.Activity size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/network-traffic",
                        redirectExternal: false
                    },
                    // {
                    //   id: "pieBar",
                    //   title: "Pie, bar",
                    //   type: "item",
                    //   icon: <Icon.Circle size={12} />,
                    //   permissions: ["admin", "editor"],
                    //   navLink: "/"
                    // }
                ]
            },
            {
                id: "sdwan-reports",
                title: "Reports",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/sdwan-reports"
            },
            // {
            //     id: "sub7",
            //     title: "Reports",
            //     type: "collapse",
            //     icon: <Icon.Circle size={20} />,
            //     children: [
            //         {
            //             id: "report",
            //             title: "Firewall-History",
            //             type: "item",
            //             icon: <Icon.Layers size={20} />,
            //             permissions: ["admin", "editor"],
            //             navLink: "/reports/firewall-history",
            //             redirectExternal: false
            //         }, {
            //             id: "policystatus",
            //             title: "Policy-Status",
            //             type: "item",
            //             icon: <BsShieldLockFill size={20} />,
            //             permissions: ["admin", "editor"],
            //             navLink: "/reports/policy-status",
            //             redirectExternal: false
            //         }
            //     ]
            // },
            
            // {
            //   id: "report",
            //   title: "Reporting",
            //   type: "collapse",
            //   icon: <Icon.Link size={20} />,
            //   children: [
            //     {
            //       id: "monthlyReport",
            //       title: "Monthly Basis Reporting",
            //       type: "item",
            //       icon: <Icon.Circle size={12} />,
            //       permissions: ["admin", "editor"],
            //       navLink: "//"
            //     }     
            //   ]
            // },

            {
                id: "15",
                title: "Settings",
                type: "item",
                icon: <Icon.Settings size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/version",
                redirectExternal: false
            },

            /*{
                id: "15",
                title: "Documentation",
                type: "item",
                icon: <FaFileAlt size={20} />,
                permissions: ["admin", "editor"],
                navLink: "https://docs.youcloudwan.com/user-guides/",
                redirectExternal: true
            },*/

            // {
            //     id: "14",
            //     title: "Help",
            //     type: "item",
            //     icon: <Icon.HelpCircle size={20} />,
            //     permissions: ["admin", "editor"],
            //     navLink: "/help",
            //     redirectExternal: false
            // },
            {
                id: "subHelp",
                title: "Help",
                type: "collapse",
                icon: <Icon.HelpCircle size={20} />,
                children: [
                    /*{
                        id: "help",
                        title: "Help",
                        type: "item",
                        icon: <Icon.HelpCircle size={20} />,
                        permissions: ["admin", "editor"],
                        navLink: "/help"
                    },*/
                    {
                        id: "15",
                        title: "Documentation",
                        type: "item",
                        icon: <FaFileAlt size={12} />,
                        permissions: ["admin", "editor"],
                        navLink: "https://docs.youcloudwan.com/user-guides/",
                        redirectExternal: true
                    },
                    {
                        id: "FAQ",
                        title: "FAQ",
                        type: "item",
                        icon: <Icon.HelpCircle size={12} />,
                        permissions: ["admin", "editor"],
                        navLink: "/quick-help"
                    },

                    /*{
                        id: "chat",
                        title: "Chat with US",
                        type: "item",
                        icon: <Icon.Circle size={12} />,
                        permissions: ["admin", "editor"],
                        navLink: "/chat"
                    }*/
                ]
            },
        ]
    },
];

const zoomMenu = [
    {
        id: "zoom",
        title: "Zoom Cloud",
        type: "double-collapse",
        order: 6,
        icon: <RiDashboardFill size={20} />,
        children: [
            {
                id: "zoom-dashboard",
                title: "Dashboard",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/zoom-dashboard"
            },
            {
                id: "user-management",
                title: "Users",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/zoom-users"
            },
            {
                id: "meeting-management",
                title: "Meetings",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/zoom-meetings"
            },
            {
                id: "webinar-management",
                title: "Webinars",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/zoom-webinars"
            },
            {
                id: "zoom-reports",
                title: "Reports",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/zoom-reports"
            },
            {
                id: "cloud-recordings",
                title: "Cloud Recordings",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/zoom-recordings"
            },
            {
                id: "zoom-calender",
                title: "Calender",
                type: "item",
                icon: <FaHome size={20} />,
                permissions: ["admin", "editor"],
                navLink: "/zoom-calender"
            },
        ],

    },
];

export {
    sidebarList,
    NewUserLists,
    sdwanMenu,
    singleVirtualboxMenu,
    zoomMenu
}
