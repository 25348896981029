import { Progress } from 'antd';
import React from 'react'
import { useEffect, useState} from 'react'

const Loading = ({loading, loadingStatus, adjustHeight=200}) => {
    const [progressPercent, setProgressPercent] = useState(0);
    
    useEffect(() => {
        const intervalId = setInterval(() => {
          if (loading) {
            setProgressPercent((prevPercent) => {
              const newPercent = prevPercent + 5;
              if (newPercent > 95) {
                return 95;
              }
              return newPercent;
            });
          }
        }, 50);
    
    
        return () => {
          clearInterval(intervalId);
        };
      }, [loading]);
    
      useEffect(() => {
        if (!loading) { 
            setProgressPercent(0)
        }
    }, [loading])
  return (
    <div>
          {loading && 
            <div
            style={{ width: "500px", height: `calc(100vh - ${adjustHeight}px)`, position: "relative", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "0 auto" }}
          >
            <Progress percent={progressPercent} size='small' showInfo={false} />
            <span>{loadingStatus ? loadingStatus : 'Loading...'}</span>
          </div>
            }
    </div>
  )
}

export default Loading;