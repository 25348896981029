import React, { useEffect, useState, useContext } from 'react';
import { Tree, Button } from 'antd';
import ConfirmationAlert from '../components/ConfirmationAlert';
import { PlusCircleOutlined, CaretDownOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import CommonModal from '../components/Modal/CommonModal.jsx';
import { Context } from '../store/Store';


const StyledContainer = styled.div`
    .ant-tree{
        letter-spacing: 0.3px !important;    
    }
    .ant-tree .ant-tree-treenode{
        padding: 0 0 10px 0 !important;
    }
`;

const { TreeNode } = Tree;


const AdminTreeMenu = ({ menuItems, onSubMenuClick, onRemoveSubMenu, onSelectMenuItem, setUpdateParentList, showConfirmModal=false, updateModalStatus }) => {

    const [deviceState, dispatch] = useContext(Context);
    const [isEdittedPage, setIsEdittedPage] = useState(false);
    const [isVisibleModal, setIsVisibleModal] = useState(showConfirmModal ?? false);    

    const { documents = {} } = deviceState ?? {};
    const { isPageEdit, onSave, onDestroy } = documents;

    useEffect(() => {
        console.log('cuv855', showConfirmModal);
        setIsEdittedPage(isPageEdit);
    }, [deviceState, isPageEdit]);

    useEffect(() => {
        setIsVisibleModal(showConfirmModal);
    }, [showConfirmModal])


    const customSwitcherIcon = ({ isLeaf, expanded }) => {
        if (isLeaf) {
            return null; // Return null for leaf nodes without an icon
        }
        return expanded ? <CaretDownOutlined /> : <CaretRightOutlined />;
    };

    const handleSave = () => {
        onSave();
        setIsVisibleModal(false);
        updateModalStatus(false);
      };
    
      const handleDiscard = () => {
        onDestroy();
        setIsVisibleModal(false);
        updateModalStatus(false);
        // window.location.reload();
      };

    const renderMenuItems = (items, onSubMenuClick, onRemoveSubMenu, onExpand, parentKey = 'menu') => {
        const handleMenuItemClick = (menuItem) => {
            if(isEdittedPage){
                setIsVisibleModal(true);
            }
            else {
                onSelectMenuItem(menuItem);
                menuItem.pageCode && setUpdateParentList(menuItem);
            }
        };

        return items.map((menuItem, index) => (
            <TreeNode
                switcherIcon={customSwitcherIcon}
                key={`${parentKey}-${index}`}
                title={
                    <div className="title-container" onClick={() => handleMenuItemClick(menuItem)}>
                        {/* {onSelectMenuItem(menuItem)} */}
                        <span className="title">{menuItem.title}</span>
                        <div>{
                            menuItem.pageCode && 
                            <PlusCircleOutlined
                                className="add-sub-icon add-sub-button"
                                onClick={() => {
                                    if(!isEdittedPage) onSubMenuClick(menuItem);
                                }}
                                // style={{
                                //     color: menuItem.children.length >= 3 ? 'gray' : 'blue', // Adjust icon color as needed
                                //     cursor: menuItem.children.length >= 3 ? 'not-allowed' : 'pointer', // Change cursor style
                                // }}
                            />
                }
                {
    menuItem.pageCode && (
        isEdittedPage ? (
            <DeleteOutlined
                className="add-sub-icon add-sub-button"
                style={{
                    width: '18px',
                    height: '18px',
                    zIndex: '9999',
                    cursor: 'pointer', // Optional: show cursor as not-allowed
                }}
                onClick={(e) => e.preventDefault()} // Prevent any action when edited
            />
        ) : (
            <ConfirmationAlert 
                action={() => onRemoveSubMenu(menuItem, menuItem.title)}
                title='Are you sure you want to delete this menu?'
            >
                <DeleteOutlined
                    className="add-sub-icon add-sub-button"
                    style={{
                        width: '18px',
                        height: '18px',
                        zIndex: '9999',
                        color: 'blue',
                        cursor: 'pointer',
                    }}
                />
            </ConfirmationAlert>
        )
    )
}


                        </div>
                        

                        

                        
                    </div>
                }
                selectable={false}
                onExpand={(expanded, nodeInfo) => onExpand(expanded, nodeInfo, menuItem)}
            >
                {menuItem.children && menuItem.children.length > 0
                    ? renderMenuItems(menuItem.children, onSubMenuClick, onRemoveSubMenu, onExpand, `${parentKey}-${index}`)
                    : null}
            </TreeNode>
        ));
    };





    const handleExpand = (expandedKeys, { expanded }, menuItem) => {
        if (expanded) {
            // You can add logic to load or fetch data for the expanded parent menu here if needed
        }
    };

    return (
        <StyledContainer>
        <Tree
            defaultExpandAll
            showLine
            onExpand={(expandedKeys, info) => handleExpand(expandedKeys, info, menuItems)}
        >
            {renderMenuItems(menuItems, onSubMenuClick, onRemoveSubMenu, handleExpand)}
        </Tree>

        
            <CommonModal title="Documents" isMeetingModal={isVisibleModal} setIsMeetingModal={setIsVisibleModal} onHandleCancel={() => updateModalStatus(false)} width={530} footer={[
                    <Button key="discard" onClick={handleDiscard}>
                    Discard
                    </Button>,
                    <Button key="save" type="primary" onClick={handleSave}>
                    Save
                    </Button>,
                ]}>
            <p style={{fontSize: '1rem'}}>Do you want to save changes before switching pages?</p>
      </CommonModal>
        
        
        </StyledContainer>
    );
};

export default AdminTreeMenu;


